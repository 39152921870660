import React, { useEffect, useState } from "react"
import { changeCategory, changeKeyword } from '../../reducks/items/operations'
import { MainContentH2 } from '../../components/UIkit'
import DocumentMeta from 'react-document-meta'
import { SiteTitle } from './common'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { ApiDir, ImagesDir, RouteDir, ItemImageDir } from '../../common'

const Home = () =>
{
  const dispatch = useDispatch()

  const [recommendItems, setRecommendItems] = useState([])

  const [newsId, setNewsId] = useState('')
  const [newsTitle, setNewsTitle] = useState('')
  const [newsBody, setNewsBody] = useState('')
  const [newsdate, setNewsdate] = useState('')

  const [Items, setItems] = useState([])

  useEffect(()=>
  {
    //おすすめ商品情報の取得
    let params = new URLSearchParams();
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectRecommendItemList.php',params)
    .then(function(response){
      setRecommendItems(response.data)
    })
    .catch(function(error){
      console.log(error)
      return
    })

    //お知らせ情報の取得
    params = new URLSearchParams();
    params.append('limit','1');
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectPublicNewsList.php',params)
    .then(function(response){
      console.log(response.data[0])
      //お知らせIDのセット
      setNewsId(response.data[0].id)
      //お知らせタイトルのセット
      setNewsTitle(response.data[0].title)

      //お知らせ内容のHTML整形・セット
      const bodyHtml = new DOMParser().parseFromString(response.data[0].body, 'text/html')
      const maxLength = 100 //文字数上限
      let modStr = ''
      let bodyString = bodyHtml.documentElement.textContent
      let bodyText = bodyString.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')
      if(bodyText.length > maxLength){
        modStr = bodyText.substr(0, maxLength) + '...'
      }
      else
      {
        modStr = bodyText
      }
      setNewsBody(modStr)

      //お知らせ日付の整形・セット
      const date = new Date(response.data[0].publication_datetime)
      setNewsdate(date.getFullYear()+ '.' + (date.getMonth() + 1) + '.' + date.getDate())
    })
    .catch(function(error){
      console.log(error)
      return
    })

    //商品一覧の取得
    params = new URLSearchParams();
    params.append('limit','4');
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectPublicItemList.php',params)
    .then(function(response){
      setItems(response.data)
    })
    .catch(function(error){
      console.log(error)
      return
    })

  },[dispatch])

  const newsH2Style =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/top_news_h2_background_image.png')`
  }

  const menuListFruitStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_fruit.png')`
  }

  const menuListMeatStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_meat.png')`
  }

  const menuListVegetableStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_vegetable.png')`
  }

  const menuListProcessedGoodsStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_Processed_goods.png')`
  }

  const menuListFlowerArrangementStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_flower_arrangement.png')`,
    backgroundSize: '35px',
    backgroundPosition: 'right 37px center'
  }

  const menuListOtherStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_other.png')`
  }

  const meta =
  {
    title: SiteTitle,
  }

  const selectItemListByCategory = (id)=>
  {
    dispatch(push(RouteDir + '/item/list?category=' + id))
  }

  return(
    <DocumentMeta {...meta}>
      <main className="top_main_content">
        {/* <section className="recommend_item_list_area">
          <MainContentH2
            title = {'おすすめの商品'}
            boderColor = {'#00584D'}
          />
          <div className="item_list_area">
            {Array.isArray(recommendItems) && recommendItems.map((item, i) => (
              <div className={i<3?"recommend_item_card big_card":"recommend_item_card"} key={i} onClick={()=>dispatch(push(RouteDir+'/item/detail/'+item.id))}>
                <div className="thumbnail_area" style={item.path!==null?{backgroundImage:`url('${ItemImageDir}${item.path}')`}:{backgroundImage:`url('${process.env.PUBLIC_URL}/images/noimage.jpg')`}}></div>
                <p className="item_name">{item.name}</p>
                <p className="item_price">￥{(Math.floor(Number(item.price)*(1+(Number(item.tax)/100)))).toLocaleString()}(税込)</p>
              </div>
            ))}
          </div>
        </section> */}
        <section className="shop_introduce_area">
          <div className="wrap">
            <h2>貝だしらーめん<span className="small">の</span><span>誕</span><span>生</span><span>秘</span><span>話</span></h2>
            <div className="text_area">
              <img src={process.env.PUBLIC_URL + '/images/top_introduce_image.jpg'} alt="" />
              <p>豚骨ラーメン等、動物性の油のラーメンを食べるのが、年々辛くなってきた、飲んだ次の日に食べると胃もたれすることが多い！等の話を知人やお客様より聞くようになり、長崎には豚骨ラーメンは多いが、あっさりスープのラーメンがないことに気付きました。そして貝専門店をしていることから、海の町なのに、魚や貝のスープのラーメンが知る限りありません（豚骨に魚貝を混ぜたものはあるが）。そして貝だしラーメンを開発し、お店で提供を始めると、女性や年配者を中心に豚骨ラーメン離れしていた方々や、飲んだのお客様に飲み干せるスープとして人気が出ました。</p>
            </div>
          </div>
        </section>
        <section className="ramen_area">
          <div className="ramen_item">
            <div className="ramen_img">
              <img src={process.env.PUBLIC_URL + '/images/ramen_img.jpg'} alt="" />
            </div>
            <div className="ramen_txt">
              <h3>貝だしらーめん</h3>
              <p>魚と貝のだしをメインにした</p>
              <ul>
                <li>あっさりした味わい</li>
                <li>低カロリー</li>
                <li>高タンパク質</li>
              </ul>
              <p>健康・美食にも目を向けた新感覚のラーメンです。</p>
            </div>
          </div>
          <div className="ramen_info">
            <p>他社の魚介スープは、魚介の出汁＋豚骨や白湯スープ等の動物性をメインにしている為どっしりとしたパンチのある味わいのスープが多いですが、当店は魚介スープをメインにあっさりした味わいが口あたり胃袋にまで楽しめます。</p>
            <img src={process.env.PUBLIC_URL + '/images/ramen_logo.png'} alt="" />
          </div>
        </section>
        {/* <section className="news_area">
          <h2 style={newsH2Style}>JAショップからのお知らせ</h2>
          <div className="news_card" onClick={()=>dispatch(push(RouteDir + '/news/detail/'+newsId))}>
            <p className="title"><span className="date">{newsdate}</span>{newsTitle}</p>
            <p className="content_html" dangerouslySetInnerHTML={{__html: newsBody}}></p>
          </div>
          <button className="btn_type_1" onClick={()=>dispatch(push(RouteDir+'/news/list'))}>お知らせ一覧へ</button>
        </section> */}
        <section className="item_list_area">
          <MainContentH2
            title = {'らーめん'}
            boderColor = {'#00584D'}
          />
          <div className="item_list">
            {Array.isArray(Items) && Items.map((item, i) => (
              <div className={"item_card"} key={i} onClick={()=>dispatch(push(RouteDir+'/item/detail/'+item.id))}>
                <div className="thumbnail_area" style={item.path!==null?{backgroundImage:`url('${ItemImageDir}${item.path}')`}:{backgroundImage:`url('${process.env.PUBLIC_URL}/images/noimage.jpg')`}}></div>
                <p className="item_name">{item.name}</p>
                <p className="item_price">￥{(Math.floor(Number(item.price)*(1+(Number(item.tax)/100)))).toLocaleString()}(税込)</p>
              </div>
            ))}
          </div>
          <button className="btn_type_1" onClick={()=>dispatch(push(RouteDir+'/item/list'))}>商品一覧へ</button>
        </section>
        {/* <section className="category_list_area">
          <MainContentH2
            title = {'商品カテゴリ'}
            boderColor = {'#00584D'}
          />
          <div className="category_list">
            <div className="category_card" style={menuListFruitStyle} onClick={()=>selectItemListByCategory(1)}>
              <span>果物</span>
            </div>
            <div className="category_card" style={menuListMeatStyle} onClick={()=>selectItemListByCategory(2)}>
              <span>お肉</span>
            </div>
            <div className="category_card" style={menuListVegetableStyle} onClick={()=>selectItemListByCategory(3)}>
              <span>野菜</span>
            </div>
            <div className="category_card" style={menuListProcessedGoodsStyle} onClick={()=>selectItemListByCategory(4)}>
              <span>加工品</span>
            </div>
            <div className="category_card" style={menuListFlowerArrangementStyle} onClick={()=>selectItemListByCategory(5)}>
              <span>生花</span>
            </div>
            <div className="category_card" style={menuListOtherStyle} onClick={()=>selectItemListByCategory(6)}>
              <span>その他</span>
            </div>
          </div>
        </section> */}
      </main>
    </DocumentMeta>
  )
}

export default Home