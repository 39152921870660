import React, { useCallback, useEffect, useState } from 'react'
import { changeCategory, changeKeyword } from '../../reducks/items/operations'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RouteDir } from "../../common"


const Sidebar = () =>
{
  const dispatch = useDispatch();

  const keyword = useSelector(state => state.items.selectKeyword)
  //検索商品名の入力
  const [searchNameValue, setSearchName] = useState(keyword)
  const inputSearchName = useCallback((event) =>
  {
    setSearchName(event.target.value)
  }, [setSearchName])

  useEffect(()=>{
    setSearchName(keyword)
  },[keyword])

  const menuListFruitStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_fruit.png')`
  }

  const menuListMeatStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_meat.png')`
  }

  const menuListVegetableStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_vegetable.png')`
  }

  const menuListProcessedGoodsStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_Processed_goods.png')`
  }

  const menuListFlowerArrangementStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_flower_arrangement.png')`,
    backgroundSize: '25px',
    backgroundPosition: 'right 37px center'
  }

  const menuListOtherStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_other.png')`
  }

  const searchInputStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_search.svg')`
  }

  const selectItemListByCategory = (id)=>
  {
    setSearchName('')
    // dispatch(changeCategory(id))
    dispatch(push(RouteDir + '/item/list?category=' + id))
  }

  const selectItemListByKeyword = (searchNameValue)=>
  {
    // dispatch(changeKeyword(searchNameValue))
    dispatch(push(RouteDir + '/item/list?keyword=' + searchNameValue))
  }


  return(
    <aside className={'sidebar'}>
      <div className="search_area">
        <input
          type = "text"
          name = "search_name"
          style = {searchInputStyle}
          value = {searchNameValue}
          onChange = {inputSearchName}
          placeholder = {'キーワード検索'}
        />
        <button onClick={()=>selectItemListByKeyword(searchNameValue)}>検索</button>
      </div>
      <div className="menu_list">
        <h2>Webメニュー</h2>
        <ul>
          <li onClick={()=>dispatch(push(RouteDir+'/mypage/favorite_list'))}>
            <span>お気に入り商品</span>
          </li>
          <li onClick={()=>dispatch(push(RouteDir+'/mypage/cart_list'))}>
            <span>買い物カゴ</span>
          </li>
          <li onClick={()=>dispatch(push(RouteDir+'/contact'))}>
            <span>お問い合わせ</span>
          </li>
          <li onClick={()=>dispatch(push(RouteDir+'/signup_application'))}>
            <span>新規会員登録</span>
          </li>
          <li onClick={()=>dispatch(push(RouteDir+'/login'))}>
            <span>ログイン</span>
          </li>
        </ul>
      </div>
    </aside>
  )
}

export default Sidebar