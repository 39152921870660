import React, { useEffect, useCallback, useState } from "react"
import DocumentMeta from 'react-document-meta'
import { insertOrderTemp } from '../../reducks/orders/operations'
import { useDispatch, useSelector } from 'react-redux'
import { BreadCrumb, OrderFlow } from '../../components'
import PaymentPage from "../../components/public/PaymentPage"
import { SiteTitle } from './common'
import axios from 'axios'
import { push } from "connected-react-router"
import {
  HomeUrl,
  postUrl,
  merchantId,
  merchantName,
  copyRight,
  ApiDir,
  RouteDir,
  ItemImageDir,
} from '../../common'


const OrderConfirm = () =>
{
  const dispatch = useDispatch()

  //注文情報
  const orders = useSelector(state => state.orders.list)
  const payType = useSelector(state => state.orders.payType)

  //注文商品情報
  const items = useSelector(state => state.carts.list)

  //お届け先
  const postAddress = useSelector(state => state.orders.postAddress)

  //商品合計
  const [totalItemPrice, setTotalItemPrice] = useState(0)

  //送料合計
  const [totalPostage, setTotalPostage] = useState(0)

  //手数料
  const [commission, setCommission] = useState(0)

  //決済ID
  const [paymentId, setPaymentId] = useState('')

  //ハッシュ値
  const [hash, setHash] = useState('')

  //決済IDの生成
  const getDateTime = ()=>
  {
    const toDoubleDigites = (num)=>
    {
      let number = String(num)
      if(number.length===1)
      {
        number = '0' + number
      }
      return number
    }
    const date = new Date()
    const datetime = String(date.getFullYear())+toDoubleDigites(date.getMonth()+1)+toDoubleDigites(date.getDate())+toDoubleDigites(date.getHours())+toDoubleDigites(date.getMinutes())+toDoubleDigites(date.getSeconds())
    return datetime
  }

  useEffect(()=>{
    const totalPriceClac = () => {
      const itemCount = items.length
      let totalPrice = 0
      for(let i=0;i<itemCount;i++)
      {
        totalPrice += (Number(items[i].price)*Number(items[i].quantity))
      }
      return totalPrice
    }
    setTotalItemPrice(totalPriceClac())
  },[items])

  const OrderDecideBtn = () =>
  {
    console.log(paymentId)
  }


  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '買い物カゴ',
      href: '/mypage/cart_list'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="order_confirm_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="order_confirm_content">
          <h1>ご入力内容の確認</h1>
          <OrderFlow
            activeKey = {2}
          />
          <section className="list_type_4">
            <div className="order_info_list">
              <div className="order_card_box">
                <h2>注文情報</h2>
                <div className="list_type_2">
                  <table>
                    <thead>
                      <tr>
                      <th className="item_name_th">商品名</th>
                      <th>金額（税込）</th>
                      <th>個数</th>
                      </tr>
                    </thead>
                    <tbody>
                  {Array.isArray(items) && items.map((item, i) => (
                    <tr key={i}>
                      <td className="item_image_name_box">
                        <img src={item.path===null?process.env.PUBLIC_URL + '/images/noimage.jpg':ItemImageDir + item.path} alt={item.name} />
                        <div>
                          <p className="name">{item.name}</p>
                          <p className="standard">{item.standard}</p>
                        </div>
                      </td>
                      <td className="price_box">￥{Number(Math.floor(Number(item.price)*(1+(Number(item.tax_value)/100)))).toLocaleString()}</td>
                      <td className="quantity_box">
                        {item.quantity}個
                      </td>
                    </tr>
                  ))}
                </tbody>
                  </table>
                </div>
                <div className="order_card">
                  <dl>
                    <dt>お届け先</dt>
                    <dd>
                      <p>{postAddress[0].family_name}　{postAddress[0].first_name} 様</p>
                      <p>〒{postAddress[0].postal_code.slice(0,3)}-{postAddress[0].postal_code.slice(-4)}<br/>{postAddress[0].address}</p>
                      <p>{postAddress[0].telnumber}</p>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="total_price_box">
                <h2>お支払金額</h2>
                {/* <dl>
                  <dt>商品合計</dt>
                  <dd>¥{totalItemPrice.toLocaleString()}</dd>
                </dl> */}
                {/* <dl>
                  <dt>送料</dt>
                  <dd>¥{totalPostage.toLocaleString()}</dd>
                </dl> */}
                {/* <dl>
                  <dt>手数料</dt>
                  <dd>¥{commission.toLocaleString()}</dd>
                </dl> */}
                <dl>
                  <dt>合計</dt>
                  <dd className="total_price">¥{Number(Math.floor(Number(totalItemPrice)*1.1)).toLocaleString()}</dd>
                </dl>
              </div>
            </div>
            <div>
              <PaymentPage
                procedure = {''}
                orderId = {''}
                totalPrice = {totalItemPrice}
                familyName = {''}
                firstName = {''}
                postalCode = {''}
                address = {''}
                telnumber = {''}
              />
            </div>
            <div className="btn_area">
              <button className="order_info_link_btn" onClick={()=>dispatch(push(RouteDir+'/mypage/add_order_post_address'))}>お届け先入力へ戻る</button>
              <button className="order_decide_btn" onClick={()=>OrderDecideBtn()}>決済する</button>
            </div>
          </section>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default OrderConfirm